@charset "utf-8";

/**
 *
 *  font
 *
 */

/*--------------------------------------------------------------------------
   import
---------------------------------------------------------------------------*/
@font-face {
    font-family: 'Noto Sans Japanese';
    font-style: normal;
    font-weight: 300;
    src: url('/assets/font/NotoSansCJKjp-DemiLight.eot');
    src: url('/assets/font/NotoSansCJKjp-DemiLight.eot?#iefix') format('embedded-opentype'),
    url('/assets/font/NotoSansCJKjp-DemiLight.woff') format('woff'),
    url('/assets/font/NotoSansCJKjp-DemiLight.ttf')  format('truetype');
}
@font-face {
    font-family: 'Noto Sans Japanese';
    font-style: normal;
    font-weight: 500;
    src: url('/assets/font/NotoSansCJKjp-Medium.eot');
    src: url('/assets/font/NotoSansCJKjp-Medium.eot?#iefix') format('embedded-opentype'),
    url('/assets/font/NotoSansCJKjp-Medium.woff') format('woff'),
    url('/assets/font/NotoSansCJKjp-Medium.ttf')  format('truetype');
}
@font-face {
    font-family: 'Noto Sans Japanese';
    font-style: normal;
    font-weight: 700;
    src: url('/assets/font/NotoSansCJKjp-Bold.eot');
    src: url('/assets/font/NotoSansCJKjp-Bold.eot?#iefix') format('embedded-opentype'),
    url('/assets/font/NotoSansCJKjp-Bold.woff') format('woff'),
    url('/assets/font/NotoSansCJKjp-Bold.ttf')  format('truetype');
}
@font-face {
	font-family: 'Noto Sans Japanese';
	font-style: normal;
	font-weight: 900;
	src: url('/assets/font/NotoSansCJKjp-Black.eot');
	src: url('/assets/font/NotoSansCJKjp-Black.eot?#iefix') format('embedded-opentype'),
		url('/assets/font/NotoSansCJKjp-Black.woff') format('woff'),
		url('/assets/font/NotoSansCJKjp-Black.ttf')  format('truetype');
}